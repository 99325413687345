<template>
  <div>
    <a
      :rel="opensInNewWindow ? 'noopener' : null"
      :href="dynamicHref"
      :target="linkTarget"
      @click="shouldOpenDialog"
      v-html="title"
    />
    <ref-link-dialog
      :external-link="externalLink"
      :is-dialog-visible="isDialogVisible"
      :link-target="linkTarget"
      @close="closeDialog"
    />
  </div>
</template>

<script>
import storefrontInstance from '@/api/instances/storefront';
import RefLinkDialog from '@/components/ref-link/RefLinkDialog';
import {mapGetters} from 'vuex';

export default {
  name: 'RefLink',
  components: {RefLinkDialog},
  props: {
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    assetType: {
      type: String,
      default: undefined,
    },
    assetLocation: {
      type: String,
      default: undefined,
    },
    target: {
      type: String,
      default: undefined,
    },
    reportName: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isDialogVisible: false,
      externalLink: '',
      dynamicHref: '',
    };
  },
  computed: {
    ...mapGetters('viewSize', ['viewBreakpointLessThan']),
    isAzureLink() {
      return this.assetLocation === 'Azure';
    },
    isCloudinaryLink() {
      return this.assetLocation === 'Cloudinary';
    },
    isPlainLink() {
      return !this.isAzureLink && !this.isCloudinaryLink;
    },
    isContent() {
      return this.assetType === 'content';
    },
    linkTarget() {
      return this.opensInNewWindow ? '_blank' : '_self';
    },
    opensInNewWindow() {
      return this.target?.toUpperCase() === 'NEWWINDOW';
    },
    hasExternalLinkAtBreakpoint() {
      return this.externalLink && this.viewBreakpointLessThan('large');
    },
  },
  mounted() {
    this.processLink();
  },
  methods: {
    processLink() {
      if (this.isAzureLink) {
        if (this.reportName) {
          return this.openGptReportLink();
        }
        this.openLink('processLink');
      }

      if (this.isCloudinaryLink) {
        this.openCloudinaryLink();
      }
      if (this.isPlainLink) {
        this.dynamicHref = this.link;
      }
    },
    async openLink(processUrl) {
      const {data} = await storefrontInstance.get(
        `/product/spec/${processUrl}?assetLocation=${this.assetLocation}&linkUrl=${escape(this.link)}`
      );
      if (!data || !data.data) throw Error(`Bad document link: ${data.data}`);
      this.dynamicHref = data.data;
      this.externalLink = this.dynamicHref;
    },
    async openGptReportLink() {
      const url = `/scorecard/processGptwLink?assetLocation=${this.assetLocation}&linkUrl=${encodeURIComponent(
        this.link
      )}`;
      const {data} = await storefrontInstance.get(url).catch((error) => {
        console.error(`Document link generation error: ${error}`);
      });
      if (data?.data) {
        this.dynamicHref = data.data;
      }
    },
    openCloudinaryLink() {
      const baseUrl = '//media.trekbikes.com/image/upload/';
      const transformations = this.isContent ? '' : 'w_160,h_120,f_auto,q_auto,fl_strip_profile/';
      const url = `${baseUrl}${transformations}${this.link}`;
      this.dynamicHref = url;
    },
    shouldOpenDialog(event) {
      if (this.hasExternalLinkAtBreakpoint) {
        this.isDialogVisible = true;
        event.preventDefault();
      }
    },
    closeDialog() {
      this.isDialogVisible = false;
    },
  },
};
</script>

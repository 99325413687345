import storefrontInstance from '@/api/instances/storefront';
import occInstance from '@/api/instances/occ';
import {DealersDefaultParams, ModesDefaultParams} from '@/constants/dealer-list';
import backendStore from '@/store/modules/backend';

const DeliveryMethodApi = {
  async getDealers({userId, cartId}, postalCode, deliveryMode) {
    const url = `users/${userId}/carts/${cartId}/stores`;
    const {CURRENT_PAGE, PAGE_SIZE, FIELDS} = DealersDefaultParams;
    const params = {
      lang: backendStore.state.language,
      query: postalCode,
      currentPage: CURRENT_PAGE,
      pageSize: PAGE_SIZE,
      fields: FIELDS,
      ...(deliveryMode ? {deliveryMode} : {}),
    };
    const response = await occInstance.get(url, {params});
    return response?.data?.stores;
  },

  async getDeliveryModes({userId, cartId}, selectedStore, isSuppressingHomeDelivery) {
    const url = `users/${userId}/carts/${cartId}/deliverymodes`;
    const {CURRENT_PAGE, PAGE_SIZE, FIELDS} = ModesDefaultParams;
    const params = {
      currentPage: CURRENT_PAGE,
      pageSize: PAGE_SIZE,
      fields: FIELDS,
      ...(selectedStore ? {store: selectedStore} : {}),
      suppressHomeDelivery: isSuppressingHomeDelivery ? isSuppressingHomeDelivery : false,
    };
    const response = await occInstance.get(url, {params});
    return response?.data?.deliveryModes;
  },

  async submitDeliveryMethod(deliveryMethodData) {
    const url = 'checkout/multi/delivery-method/select';
    const response = await storefrontInstance.get(url, {params: deliveryMethodData});
    return response.request.responseURL;
  },
};

export {DeliveryMethodApi};

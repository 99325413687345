import Vue from 'vue';
import storefrontInstance from '@/api/instances/storefront';
import {CartApi} from '@/api/cart';
import store from '@/store';

export const UPDATE_CART_EVENT = 'update.cart';

const miniCartModule = {
  namespaced: true,

  state: {
    items: [],
    bikes: {},
    parts: {},
    customWaterBottles: {},
    projectOneBikes: {},
    cart: {},
    containers: null,
    isLoading: false,
  },

  getters: {
    totalItems: (state) => {
      return state.cart.details.totalQuantity;
    },
  },

  mutations: {
    setBikes(state, payload) {
      Vue.set(state, 'bikes', payload);
    },

    setParts(state, payload) {
      Vue.set(state, 'parts', payload);
    },

    setCustomWaterBottles(state, payload) {
      Vue.set(state, 'customWaterBottles', payload);
    },

    setProjectOneBikes(state, payload) {
      Vue.set(state, 'projectOneBikes', payload);
    },

    setCart(state, payload) {
      Vue.set(state, 'cart', payload);
    },

    setItems(state, payload) {
      Vue.set(state, 'items', payload);
    },
    setContainers(state, payload) {
      Vue.set(state, 'containers', payload);
    },
    setIsLoading(state, payload) {
      Vue.set(state, 'isLoading', payload);
    },
  },

  plugins: [
    (store) => {
      window.addEventListener(UPDATE_CART_EVENT, () => {
        store.dispatch('fetchCart');
      });
    },
  ],

  actions: {
    async fetchMiniCart({rootState, dispatch}) {
      const isB2b = rootState?.backend?.isB2Bsite;
      if (isB2b) {
        dispatch('fetchMiniCartB2b');
        return;
      }

      dispatch('fetchCart');
    },

    async fetchCart({commit, rootGetters}) {
      commit('setIsLoading', true);
      try {
        const cartPayload = rootGetters['backend/occUrlParams'];
        if (!cartPayload.cartId) return;

        const cartData = await CartApi.getCart({...cartPayload});

        if (cartData) {
          commit('setCart', cartData);
        }
      } catch (error) {
        console.error(error);
      } finally {
        commit('setIsLoading', false);
      }
    },

    async fetchMiniCartB2b({commit}) {
      if (store?.getters['checkout/isRestrictedToRetailer']) {
        const response = await storefrontInstance.get('/minicart/isbikepurchaselimitreached/');
        store?.commit('checkout/setBikePurchaseLimitReached', response.data.data);
      }

      const {data} = await storefrontInstance.get('/minicart/simplified/');
      if (data?.data) {
        commit('setBikes', data.data?.subtotals?.BIKE || {});
        commit('setParts', data.data?.subtotals?.AFTERMARKET || {});
        commit('setCustomWaterBottles', data.data?.subtotals?.BONTRAGER_CUSTOM_BOTTLE || {});
        commit('setProjectOneBikes', data.data?.subtotals?.P1_BIKE || {});
        const hasTotalQuantityProperty = Object.hasOwn(data.data.details, 'totalQuantity');
        if (!hasTotalQuantityProperty) {
          data.data.details.totalQuantity = 0;
        }
        commit('setCart', data.data);

        if (data.data.dropShipContainer) {
          commit('setContainers', data.data.dropShipContainer);
        }
      }
    },
  },
};

export default miniCartModule;
